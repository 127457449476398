<template>
  <div class="container-fluid">
    <div class="card nw-card">
      <div class="card-header nw-card-header">
        <div class="row">
          <div class="col-6 text-left"><h4>{{ user.oid > 0 ? 'User Maintenance ' + user.firstName + ' ' + user.lastName : 'Create New User'}}</h4></div>
          <div class="col-6 text-right"><h4>{{ user.oid > 0 ? user.userName + ' [' + user.oid + ']' : ''}}</h4></div>
        </div>
      </div>
      <div class="card-body text-left">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link text-dark" :class="activePage=='profile' ? 'active' : ''"  @click="activePage='profile'" href="#">Profile</a>
          </li>
          <li class="nav-item" v-show="user.oid > 0">
            <a class="nav-link text-dark" :class="activePage=='permissions' ? 'active' : ''" @click="activePage='permissions'" href="#">Permissions</a>
          </li>
        </ul>

        <user-account v-show="activePage=='profile'" :oid="user.oid" @update="userUpdate"></user-account>
        <user-permissions v-show="activePage=='permissions'" :oid="user.oid"></user-permissions>
      </div>
    </div>
  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'
import UserAccount from './UserAccount'
import UserPermissions from './UserPermissions'

export default {
  components: { UserAccount,UserPermissions },
  watch : {
      '$route.params.oid' (n,o) {
        console.log('user.watch = ' + n)
        this.user.oid = n
      }
  },
  mounted: function() {
      this.user.oid = this.$route.params.oid
      console.log('User.mounted: ' + this.user.oid)
  },
  data () {
    return {
      activePage: 'profile',
      user: { oid: -1, lastName: '', firstName: '', userName : ''}
    }
  },
  methods : {
    userUpdate (_user)
    {
      this.user = _user
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
