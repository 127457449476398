<template>
  <div class="container-fluid mt-3">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label class="control-label">First Name</label>
          <input class="form-control" v-model="user.firstName"  type="text"/>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label class="control-label">Last Name</label>
          <input class="form-control" v-model="user.lastName"  type="text"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label class="control-label">Email</label>
          <input class="form-control" v-model="user.email"  type="text"/>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label class="control-label">Phone</label>
          <input class="form-control" v-model="user.phone"  type="text"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label class="control-label">Username</label>
          <input class="form-control" v-model="user.userName"  type="text"/>
        </div>
      </div>
      <div class="col-2 offset-1">
        <div class="form-group">
          <label class="control-label">Super User</label><br/>
          <toggle class="mt-2" v-model="suRole" color="green" on-text="On" off-text="Off"></toggle>
        </div>
      </div>
      <div class="col-2">
        <div class="form-group">
          <label class="control-label">Admin User</label><br/>
          <toggle class="mt-2" v-model="adminRole" color="green" on-text="On" off-text="Off"></toggle>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-2 offset-1">
        <div class="form-group">
          <label class="control-label">Account Locked</label><br/>
          <span class="mt-2">{{ user.locked ? 'YES' : 'NO'}}</span>
        </div>
      </div>
      <div class="col-2">
        <div class="form-group">
          <label class="control-label">Failed Logins</label><br/>
          <span class="mt-2">{{ user.failedLogins}}</span>
        </div>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-4">
        <button v-show="oid < 0" class="btn nw-btn-outline-info" @click="cancel()">Cancel</button>
      </div>
      <div class="col-4">
        <button class="btn nw-btn-success" @click="save()">Save</button>
      </div>
      <div class="col-4">
        <button class="btn nw-btn-outline-info" @click="sendInvite()" v-show="user.oid > 0 && inviteSent==false">Send Invite</button>
        <div v-show="inviteSent==true">Invite Sent</div>
      </div>
    </div>
    <Errors class="mt-3" :resp="resp" :retval="retval"></Errors>

  </div>
</template>

<script>
import Toggle from '@/components/Toggle'
import Errors from '@/components/Errors'
import NetClient from '@/util/netclient.js'

export default {
  components: { Toggle,Errors},
  props: [ 'oid'],
  watch: {
      'oid' (n,o) {
        console.log('UserAccount oid = ' + n)
        this.loadUser()
      }
  },
  mounted: function() {
      this.loadUser()
  },
  data () {
    return {
      adminRole: false,
      suRole: false,
      retval :'OK',
      resp: [],
      user: this.emptyUser(),
      inviteSent: false
    }
  },
  methods : {
    emptyUser () {
      return {
        oid: -1,
        locked: false,
        failedLogins: 0,
        uname: '',
        email: '',
        suRole: false,
        adminRole: false,
        firstName: '',
        lastName: '',
        phone: ''
      }
    },
    loadUser () {
      if(this.oid < 0)
      {
        this.user = this.emptyUser()
      } else {
        NetClient.doRequest("rest/user", { action: 'user', user: { oid: this.oid }}).then(msg => {
          if(typeof msg.data != 'undefined')
          {
            this.user = msg.data
            this.$emit('update',this.user)

            if(this.user.adminRole == 1) this.adminRole = true
            else this.adminRole = false

            if(this.user.suRole == 1) this.suRole = true
            else this.suRole = false

          }
          this.retval = msg.retval
          if(typeof msg.resp != 'undefined') this.resp = msg.resp
          else this.resp = []
          if('OK' == msg.retval) this.isDirty = false
        })
      }

    },
    save () {
      var obj = this
      if(this.adminRole == true) this.user.adminRole = 1
      else this.user.adminRole = 0

      if(this.suRole == true) this.user.suRole = 1
      else this.user.suRole = 0

        NetClient.doRequest("rest/user", { action: 'user', user: this.user}).then(msg => {
          if(typeof msg.data != 'undefined')
          {
            obj.user = msg.data
            obj.$emit('update',this.user)

            if(this.user.adminRole == 1) this.adminRole = true
            else this.adminRole = false

            if(this.user.suRole == 1) this.suRole = true
            else this.suRole = false
          }

          obj.retval = msg.retval

          if(typeof msg.resp != 'undefined') obj.resp = msg.resp
          else obj.resp = []

          if('OK' == msg.retval) obj.isDirty = false

        })
    },
    sendInvite () {
      NetClient.doRequest("rest/session", { action: 'sendInviteEmail', userID: this.user.oid}).then(msg => {
        if(msg.retval == 'OK') this.inviteSent = true
      })
    },
    cancel () {
      this.$router.push({ name: 'Users'})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
