<template>
  <div class="mt-3">
    <div v-show="activePage=='list'">
      <div class="row">
        <div class="col-6 text-left">
          <button class="btn nw-btn-outline-info" @click="activePage='search'"><i class="fa fa-plus"></i> Add Group</button>
        </div>
        <div class="col-6 text-right">
          <button class="btn nw-btn-outline-danger-sm" @click="removeGroup"><i class="fa fa-ban"></i> Remove Selected</button>
        </div>
      </div>

      <table class="table table-hover mt-4">
        <thead><tr><th>Group Name</th><th>Group Type</th><th>Remove</th></tr></thead>
        <tbody>
          <tr v-for="item in arr">
            <td>{{ item.groupName}}</td>
            <td>{{ groupType(item.groupType)}}</td>
            <td>
              <input class="form-check-input ml-4" type="checkbox" v-model="item.remove">
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <Errors :retval="retval" :resp="resp"></Errors>
    <group-search v-show="activePage=='search'" @select="success" @cancel="cancel" showCancel="true"></group-search>

  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'
import Util from '@/util/util.js'
import GroupSearch from '@/components/UserGroupSearch'
import Errors from '@/components/Errors'

export default {
  components: {GroupSearch, Errors },
  props: ['oid'],
  mounted: function()
  {
    console.log('UserPermissions.mounted: ' + this.oid)
      this.loadGroups()
  },
  watch : {
    'oid' : function(n,o)
    {
      console.log('USerPermissions.watch: ' + n)
      this.loadGroups()
    }
  },
  data () {
    return {
      activePage: 'list',
      resp: [],
      retval: 'OK',
      model: {

      },
      arr: []
    }
  },
  methods: {
    groupType: function(_type) {
      switch(_type)
      {
        case 0:
          return 'DME Vendor'
        case 1:
          return 'Clinic'
        case 2:
          return "Other"
        default:
          return "Unknown"
      }
    },
    loadGroups () {
        NetClient.doRequest('rest/user', {  action: 'userGroups', userID: this.oid }).then(msg => {
          if(typeof msg.data != 'undefined') this.arr = msg.data
        })
    },
    success (_group) {
      this.retval = 'OK'
      this.resp = []
      NetClient.doRequest('rest/user', {  action: 'userGroups', userID: this.oid, addID: _group.oid }).then(msg => {
        if(typeof msg.data != 'undefined') this.arr = msg.data
        if('OK' == msg.retval) this.activePage = 'list'
        if(typeof msg.retval != 'undefined') this.retval = msg.retval
        if(typeof msg.resp != 'undefined') this.resp = msg.resp
      })
    },
    cancel () {
      this.activePage = 'list'
    },
    removeGroup : function() {
        var ii
        var remove = []
        for(ii=0;ii<this.arr.length;ii++)
        {
          if(typeof this.arr[ii].remove != 'undefined' && this.arr[ii].remove == true) remove.push(this.arr[ii].oid)
        }

        if(remove.length > 0)
        {
          NetClient.doRequest("rest/user", { action: 'userGroups', userID: this.oid, remove: remove}).then(msg => {
            if(typeof msg.data != 'undefined') this.arr = msg.data
          })
          this.userSearch = false
        }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
